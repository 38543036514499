// import moment from 'moment';
declare const moment;
export function  interval(callback:any,time:any = 1000,) {
  return  setInterval(
    () => {
      // clearInterval(intervalCard);
      callback && callback(true);
    },time
  );
}
export function getDateWithOffset(offsetDays:any) {
  let today = new Date();

  // Добавляем к сегодняшней дате количество дней
  today.setDate(today.getDate() + offsetDays);

  let day = today.getDate().toString().padStart(2, '0'); // Делаем формат с двумя цифрами
  let month = (today.getMonth() + 1).toString().padStart(2, '0'); // Месяцы в JS начинаются с 0

  // Возвращаем строку в формате день.месяц
  return `${day}.${month}`;
}

export function dateNow(){
 return Date.now();
}
export  function  reInit(callback:any,time:any = 1, disabled:any = true) {
  let intervalInit = interval(()=>{
    callback && callback(true);
    disabled ?clearInterval(intervalInit):null;

  },time)

}
export function timeOut(callback:any,time:any = 1){
   setTimeout(()=>{
     callback && callback(true);
   }, time);
}
export function onlineStatusInternetConnection() {
  return navigator.onLine;
}
export  function subscribeRestDaysMonths(months: any = 1) {
  // const d = moment();
  // const currentDay = d.get('date');
  // const daysInMonth = d.daysInMonth();
  // const remainingDays = daysInMonth - currentDay;
  // return remainingDays;
}
export function subscribeLastDayInMonths(months: any = 1) {
  // let thisMoment = moment();
  // let endOfMonth = moment(thisMoment).endOf('month').format('DD-MM-YYYY');
  // // let startOfMonth = moment(thisMoment).startOf('month');
  // return endOfMonth;
}

export function checkEndOfMonth(callback) {
  const today = moment();
  const tomorrow = moment().add(1, 'days');

  // Получаем первый день следующего месяца
  const firstDayNextMonth = today.clone().add(1, 'month').startOf('month');

  // Проверяем, является ли завтра первым числом следующего месяца
  if (tomorrow.isSame(firstDayNextMonth, 'day')) {
    callback  && callback()
    console.log("Сегодня последний день месяца. Колбек выполнится завтра.");
    // setTimeout(callback, moment.duration(1, 'days').asMilliseconds()); // Устанавливаем колбек на выполнение через одни сутки
  } else {
    console.log("Сегодня не последний день месяца, колбек не будет установлен.");
  }
}
