import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {Photo} from '../../../interfaces/Photo';
import Swiper from 'swiper';
import {JsonPipe} from "@angular/common";

@Component({
  selector: 'app-swiper-photos',
  templateUrl: './swiper-photos.component.html',
  standalone: true,
  imports: [
    JsonPipe
  ]

})
export class SwiperPhotosComponent implements AfterViewInit, OnDestroy {
  @Input() photos: Array<Photo>;
  @Input() photoCurrent: Photo;
  @Input() indexCurrent: number = 0;

  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onSlide: EventEmitter<any> = new EventEmitter();
  @ViewChild('swiperOneThumb') swiperOneThumb: ElementRef;
  @ViewChild('swiperTwoBigImage') swiperTwoBigImage: ElementRef;
  @ViewChild('arrowLeft') arrowLeft: ElementRef;
  @ViewChild('arrowRight') arrowRight: ElementRef;
  public swiperThumbs: any;
  public swiperWindow: any;

  public ngAfterViewInit(): void {
    this.initSwiper();
  }


  public slideToIndex(indexSlide: number): void {
    this.indexCurrent = indexSlide;
    this.swiperThumbs.slideTo(indexSlide);
    this.swiperWindow.slideTo(this.indexCurrent);

  }

  public ngOnDestroy(): void {
    this.destroySwiper();
  }

  private initSwiper(): void {
    var swiper = new Swiper(this.swiperOneThumb.nativeElement, {
      loop: true,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
      pagination: {
        el: ".swiper-pagination",
      },
    });


    // @ts-ignore
    /*this.swiperThumbs = new Swiper(this.swiperOneThumb.nativeElement, {
      loop: true,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    this.swiperWindow = new Swiper(this.swiperTwoBigImage.nativeElement, {
      zoom: true,
      grabCursor: false,
      mousewheel: false,
      spaceBetween: 10, // freeMode: true,
      // autoplayDisableOnInteraction: false,
      slidesPerView: 1,
      speed: 400,
      centeredSlides: true,
      roundLengths: false,
      direction: 'horizontal',
      loop: true,
      freeMode: false,
      navigation: {
        nextEl: this.arrowLeft.nativeElement,
        prevEl: this.arrowRight.nativeElement,
      },
      thumbs: {
        swiper: this.swiperThumbs,
      },
    });
    this.swiperThumbs.on('slideChange', (event: any): void => {
      this.photoCurrent = this.photos[event.activeIndex];
      this.onSlide.emit(this.photoCurrent);

    });
    this.swiperWindow.on('slideChange', (event: any): void => {
      this.photoCurrent = this.photos[event.activeIndex];
      this.onSlide.emit(this.photoCurrent);
    });*/
  }

  private destroySwiper(): void {
   /* this.swiperThumbs.destroy();
    this.swiperWindow.destroy();
    this.swiperOneThumb = null;
    this.swiperTwoBigImage = null;
    this.photoCurrent = null;
    this.indexCurrent = 0;
    this.photos = null;*/
  }

}
