
  @if ((box && rowName && box[rowName])) {
    <app-images-viewer-grid-slidder
    [photos]="box[rowName]"
      [showGrid]=false
      (onClose)="close()"
      (onSelect)="selectItem  =$event; onGeneratePallete.emit($event)"

    ></app-images-viewer-grid-slidder>
  } @else {
  <app-preload ></app-preload>
  }
