import {Directive, ElementRef, Input, OnChanges, Renderer2} from '@angular/core';

@Directive({
  selector: '[show]',
  standalone:true,

})
export class HideDirective implements OnChanges {

  @Input() show: boolean;

  constructor(private renderer: Renderer2, private elRef: ElementRef) {}

  ngOnChanges():void {
    if (this.show) {
      this.renderer.setStyle(this.elRef.nativeElement, 'visibility', 'visible');
    } else {
      this.renderer.setStyle(this.elRef.nativeElement, 'visibility', 'hidden');
    }
  }
}
