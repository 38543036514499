<div #swiperOneThumb  class="swiper mySwiper">
  <div class="swiper-wrapper">
    @for ( photoItem of photos; track photoItem.srcPreview){

      <div class="swiper-slide">
        <img [src]="photoItem.srcPreview"
             [alt]="'photo'"
             [style.height.px]="500"
             [style.width.px]="500"
             (click)="onClick.emit(photoItem)"
        >
      </div>
    }
  </div>
  <div class="swiper-pagination"></div>
</div>

<!--<div #swiperOneThumb  class="swiper">
  &lt;!&ndash; Additional required wrapper &ndash;&gt;
  <div class="swiper-wrapper">
    @for ( photoItem of photos; track photoItem.srcPreview){

      <div class="swiper-slide">
        <img [src]="photoItem.srcPreview"
             [alt]="'photo'"
             [style.height.px]="500"
             [style.width.px]="500"
             (click)="onClick.emit(photoItem)"
        >
        &lt;!&ndash;<div class="swiper-zoom-container">
          <img [src]="photoItem.srcPreview"
               [alt]="'photo'"
               [style.height.px]="500"
               [style.width.px]="500"
               (click)="onClick.emit(photoItem)"
          >
        </div>&ndash;&gt;
      </div>
    }@empty {
      <li> There are no items. </li>
    }
    ...
  </div>
  &lt;!&ndash; If we need pagination &ndash;&gt;
  <div class="swiper-pagination"></div>

  &lt;!&ndash; If we need navigation buttons &ndash;&gt;
  <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div>

  &lt;!&ndash; If we need scrollbar &ndash;&gt;
  <div class="swiper-scrollbar"></div>
</div>-->

<!--<div style="&#45;&#45;swiper-navigation-color: #ff8a00; &#45;&#45;swiper-pagination-color: #ff8a00"
     #swiperTwoBigImage class="swiper mySwiper2">

  <div class="swiper-wrapper">
    @for ( photoItem of photos; track photoItem.srcPreview){

      <div class="swiper-slide">
        <div class="swiper-zoom-container">
          <img [src]="photoItem.srcPreview"
               [alt]="'photo'"
               [style.height.px]="500"
               [style.width.px]="500"
               (click)="onClick.emit(photoItem)"
          >
        </div>
      </div>
    }@empty {
      <li> There are no items. </li>
    }
  </div>
  <div #arrowLeft class="swiper-button-next"></div>
  <div #arrowRight class="swiper-button-prev"></div>
</div>-->

<!--<div #swiperOneThumb thumbsSlider="" class="swiper mySwiper">
  <div class="swiper-wrapper">
    @for (photoItem of photos; track photoItem.srcPreview){
      <div class="swiper-slide">
        <img [src]="photoItem.srcPreview"
             [alt]="photoItem.name"
             [style.height.px]="100"
             [style.width.px]="100"
             (click)="onClick.emit(photoItem)"
        >
      </div>
    }
  </div>
</div>-->
