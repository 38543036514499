import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { BaseApi } from './base-api';


@Injectable({
  providedIn: 'root',
})
export class ApiServices extends BaseApi {
  private url:any = 'https://server-2.artas.org.ua/';
  constructor(public http: HttpClient) {
    super(http);
  }
  requestFetch(action:string){
    async function fetchData(action:string='') {
      try {
        const response = await fetch(action);
        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData(this.url+action);
  }
  public request(action:any, data:any = null, method:any = 'post') {

    let serializeRequest:string = JSON.stringify(data);
    switch (method) {
      case 'get':
        if (data) {
          return     this.get(`${this.url + action}?request=${serializeRequest}`);
        } else {
          return this.get(`${this.url + action}`);
        }
        break;
      default:
       return   this.post(this.url + action, serializeRequest);
        break;
    }
  }
}
