export const patreonIcon: string = 'fab fa-patreon';
export const photosIcon: string = 'fa-regular fa-images';
export const uploadesIcon: string = 'fa-solid fa-camera-rotate';
export const cameraIcon: string = 'fa-solid fa-camera-retro';
export const randomIcon: string = 'fa-solid fa-shuffle';
export const tagIcon: string = 'fa-solid fa-tag';
export const folderIcon: string = 'fa-regular fa-folder-open';
export const folderCloseIcon: string = 'fa-regular fa-folder-close';
export const levelIcon: string = 'fa-solid fa-flask-vial';
export const listIcon: string = 'fa-solid fa-list';
export const pencilIcon: string = 'fa-solid fa-pencil';
export const brushIcon: string = 'fa-solid fa-paintbrush';
export const clockIcon: string = 'fa-regular fa-clock';
export const categoryIcon: string = 'fa-solid fa-certificate';
export const telegramIcon: string = 'fab fa-telegram-plane fa-lg icon-lg';
export const viberIcon: string = 'fab fa-viber fa-lg icon-lg';
export const skypeIcon: string = 'fab fa-skype fa-lg  icon-lg';
export const whatsappIcon: string = 'fab fa-whatsapp fa-lg icon-lg';
export const closeIcon: string = 'far fa-times-circle fx-10';
export const closeSquareIcon: string = 'fa-regular fa-rectangle-xmark';
export const saveIcon: string = 'far fa-save';
export const archiveIcon: string = 'fa-solid fa-box-archive';
export const colorHistoryIcon: string = 'fa-solid fa-droplet';
export const colorPalleteIcon: string = 'fa-solid fa-palette';
export const colorLibIcon: string = 'fa-solid fa-swatchbook';
export const deleteIcon: string = 'far fa-trash-alt';
export const gridIcon: string = 'fa-solid fa-border-all';
export const imagePanoramaIcon: string = 'fa-solid fa-panorama';
export const sortIcon: string = 'fa-solid fa-shuffle';
export const searchIcon: string = 'fas fa-search fx-10';
export const favoriteIcon: string = 'far fa-heart';
export const imagesIcon: string = 'fa-solid fa-images';
export const cropIcon: string = 'fa-solid fa-crop';
export const pinIcon: string = 'fa-solid fa-thumbtack';
export const favoriteOpenIcon: string = 'far fa-heart';
export const favoriteCloseIcon: string = 'fa-solid fa-heart';
export const downloadIcon: string = 'fas fa-download';
export const toolsIcon: string = 'fa-solid fa-screwdriver-wrench';
export const palleteIcon: string = 'fa-solid fa-palette';
export const palleteLibIcon: string = 'fa-solid fa-swatchbook';
export const dotesHorizontalIcon: string = 'fa-solid fa-ellipsis';
export const dotesVerticalIcon: string = 'fa-solid fa-ellipsis-vertical';
export const plusIcon: string = 'fa-regular fa-square-plus';
export const minusIcon: string = 'fa-regular fa-square-minus';
export const zoomMinusIcon: string = 'fa-solid fa-magnifying-glass-minus';
export const zoomPlusIcon: string = 'fa-solid fa-magnifying-glass-plus';
export const deliveryIcon: string = 'fa-solid fa-gift';
export const zoomIcon: string = 'fa-solid fa-magnifying-glass-plus';
export const expandIcon: string = 'fas fa-expand-arrows-alt';
export const videoIcon: string = 'fas fa-video';
export const libsIcon: string = 'fa-solid fa-book';
export const instagramIcon: string = 'fab fa-instagram fa-lg icon-lg';
export const facebookIcon: string = 'fab fa-facebook fa-lg icon-lg';
export const upArrowIcon: string = 'fas fa-arrow-up';
export const giftsIcon: string = 'fas fa-gifts fx-10';
export const basketIcon: string = 'fas fa-shopping-cart';
export const payIcon: string = 'fa-regular fa-credit-card';
export const dateIcon: string = 'fa-solid fa-calendar-days';
export const copyIcon: string = 'far fa-copy';
export const cardIcon: string = 'fa-solid fa-chess-board';
export const enterIcon: string = 'fas fa-sign-in-alt';
export const filterIcon: string = 'fas fa-sliders-h';
export const flipIcon: string = 'fas fa-arrows-alt-h';
export const dragIcon: string = 'fa-solid fa-grip-lines';
export const shareIcon: string = 'fas fa-share-alt-square';
export const editIcon: string = 'fas fa-edit';
export const addIcon: string = 'far fa-plus-square';
export const cubeIcon: string = 'fa-solid fa-cube';
export const moveIcon: string = 'fas fa-arrows-alt';
export const arrowForwardIcon: string = 'fa-solid fa-forward';
export const arrowBackwardIcon: string = 'fa-solid fa-backward';
export const arrowLeftIcon: string = 'fa-solid fa-angle-left';
export const arrowLeft2Icon: string = 'fa-solid fa-arrow-left';
export const arrowRight2Icon: string = 'fa-solid fa-arrow-right';
export const arrowRightIcon: string = 'fa-solid fa-angle-right';
export const arrowOneUp2Icon: string = 'fa-solid fa-arrow-up';
export const arrowOneUpIcon: string = 'fa-solid fa-angle-up';
export const arrowOneDownIcon: string = 'fa-solid fa-angle-down';
export const arrowOneDown2Icon: string = 'fa-solid fa-arrow-down';
export const eyeOpenIcon: string = 'fa-regular fa-eye';
export const eyeDropperIcon: string = 'fa-solid fa-eye-dropper';
export const siteMapIcon: string = 'fa-solid fa-sitemap';
export const eyeCloseIcon: string = 'fa-regular fa-eye-slash';
export const lockOpenIcon: string = 'fa-solid fa-lock-open';
export const lockCloseIcon: string = 'fa-regular fa-rectangle-list';
export const textIcon: string = 'fa-regular fa-comment';
export const handOpenIcon: string = 'fa-solid fa-hand';
export const handCloseIcon: string = 'fa-solid fa-hand-point-up';
export const giftIcon: string = 'fa-solid fa-gift';
export const premiumIcon: string = 'fa-solid fa-crown';
export const clientBadgeIcon: string = 'fa-solid fa-id-badge';
