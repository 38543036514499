import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {ScrollPanelModule} from "primeng/scrollpanel";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {OrderFormComponent} from "../order-form/order-form.component";
import {MyDataServices} from "../../../../services/my-data.services";
import {getDiscount} from "../../../../traits/string-tools";
import {CostComponent} from "../cost/cost.component";
import {CdServices} from "../../../../services/common/cd.services";
import {getDateWithOffset} from "../../../../traits/datetime-tools";
@Component({
  selector: 'app-action',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    CostComponent,
  ],
  templateUrl: './action.component.html',
})
export class ActionComponent {
  @Input() textAction: string;
  @Input() card: object;
  @Input() date: string = '20.09';
  // @Input() date: string = 'до 05.08';
  @Input() showAction: boolean =true;
  @Input() showCost: boolean = true;

  @Input()  discount: number =30;


  constructor(public cd:CdServices,) {
  }
  ngOnInit() {
    this.textAction = this.getText();
  }


getText(){
    return `Акція (знижка ${this.discount}%)`
}


  protected readonly getDiscount = getDiscount;
  protected readonly getDateWithOffset = getDateWithOffset;
 public styleAction: string = ' text-zinc-900  bg-amber-600';
}
