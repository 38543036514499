<ng-container *ngIf="selectCard && showPhotos">
  <app-sidebar [show]="showPhotos"
               (onSave)="closePhotos($event)"
               [showClose]="true"
               [height]="100"
               [ext]="'%'"
               [zIndex]="10000"
               [position]="'bottom'"
  >
    <app-box-viewer-photos
      [boxId]="boxFileName(selectCard)"
      (onDestroy)="closePhotos($event)"
      (onGeneratePallete)="setGeneratePallete($event)"
    >
    </app-box-viewer-photos>
  </app-sidebar>
</ng-container>

<app-scroll-cards-horizontal
  [cards]="dataPage.site.organizer.messenges"
></app-scroll-cards-horizontal>

<app-list-artas
  [cards]="dataPage.site.organizer.messenges"
  (onSelect)="selectItemCard($event)"
  (onSelectCourse)="onSelectCourse.emit($event)"
  (onShowPhotos)="this. selectCard = $event; showPhotos = true "
  (onClickRoute)="onClickRoute.emit($event)"
  (onFrameShow)="this. selectCard = $event; showFrame = true "
></app-list-artas>
