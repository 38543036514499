import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';

@Component({
  selector: 'app-text-slice',
  templateUrl: './text-slice.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    JsonPipe,
  ]
})
export class TextSliceComponent {
  @Input() text: string  ;
}
