declare const CryptoJS;
export const secretKey: string = 'rewrfj4564652233';

export function jsonEncrypt(json: string): string {

  return CryptoJS.AES.encrypt(JSON.stringify(json), secretKey).toString();
}
export function jsonDecrypt(jsonText: string): any {
 let  bytes = CryptoJS.AES.decrypt(jsonText, secretKey);
let    jsonString = bytes.toString(CryptoJS.enc.Utf8);
return JSON.parse(jsonString);

}
export function textEncrypt(textToEncrypt: string): string {
  return CryptoJS.AES.encrypt(textToEncrypt, secretKey).toString();
}

export function textDecrypt(textToDecrypt): string {
  let decryptedData = CryptoJS.AES.decrypt(textToDecrypt, secretKey);
  let decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
  return decryptedText
}


export function numberDecrypt(numberStringToEncrypt: string) {
  let decryptedText = textDecrypt(numberStringToEncrypt)
  return textToNumber(decryptedText)
}

export function numberEncrypt(number: number) {
  return CryptoJS.AES.encrypt(number.toString(), secretKey).toString();
}
export function textToNumber(decryptedText) {
  let number: number = parseInt(decryptedText, 10);
  return isNaN(number) ? null : number;
}
