import {Component, Input, OnInit} from '@angular/core';
import {scrollToId} from '../../../traits/window-tools';
import {Card} from '../../../interfaces/Card';
import {NgIf} from "@angular/common";
import {CostComponent} from "../../../pages/pages-jome/page-artas/cost/cost.component";
import {CdServices} from "../../../services/common/cd.services";
@Component({
  selector: 'app-scroll-cards-horizontal',
  templateUrl: './scroll-cards-horizontal.component.html',
  standalone: true,
  imports: [
    NgIf,
    CostComponent
  ]
})
export class ScrollCardsHorizontalComponent{
  @Input() cards: any[];
  @Input() width: any = 100;
  @Input() height: any = 100;
  @Input() showCountArray: any = 1000;
  constructor(public cd:CdServices,) {
  }
  public scrollToId(idFrom:any = 'myButton' ,idTo:any = 'insert2' ){
    scrollToId(idFrom,idTo)
  }
}
