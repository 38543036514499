<form class="w-full max-w-sm">
  <div class="flex items-center border-b border-teal-500 py-2">

    <img *ngIf="selectModel.srcPreview"
         class="-mt-8 inline-flex rounded-full"
         [src]="selectModel.srcPreview"
         width="64"
         height="64"
         alt="User"
    />
    <input class="appearance-none bg-transparent border-none w-full text-gray-100 mr-3 py-1 px-2 leading-tight focus:outline-none"
           type="text"
           placeholder="Введіть телефон"
           aria-label="Введіть телефон"
           [minLength]="5"
           [(ngModel)]="form.phone"
    >

    <button class="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
            type="button"
            [disabled]="!form.validation"
            (click)="submit(selectModel)"

    >
     Замовити
    </button>

  </div>
  <input class="block appearance-none bg-transparent border-none w-full text-gray-100 mr-3 py-1 px-2 leading-tight focus:outline-none"
         type="text"
         placeholder="Введіть коментар"
         aria-label="Введіть коментар"
         [minLength]="5"
         [(ngModel)]="form.comment"
  >
</form>
