<span>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         [style.width.px]="width"
         [style.height.px]="height"
         viewBox="0 0 200.000000 192.000000"
         preserveAspectRatio="xMidYMid meet">
        <g transform=""
           [attr.fill]="color"
           [attr.stroke]="stroke">
          <path [attr.d]="pathD1"/>
          <path [attr.d]="pathD2"/>
        </g>
    </svg>
</span>
