import { Component, OnInit } from '@angular/core';
import {
  CommonModule,
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet
} from '@angular/router';

import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SidebarModule} from 'primeng/sidebar';
import {NavbarTopComponent} from './components/pages/navbar-top/navbar-top.component';
import { SortablejsModule } from '@maksim_m/ngx-sortablejs';
import {filter} from "rxjs";
import {CdServices} from "./services/common/cd.services";
import {SidebarComponent} from "./components/common/sidebar/sidebar.component";
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    RouterOutlet,
    NavbarTopComponent,
    SortablejsModule,
    SidebarComponent,
    JsonPipe
  ],
  templateUrl: './app.component.html',

})

export class AppComponent implements OnInit {


  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public cd: CdServices,
  ){
  }

  ngOnInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      const navMain = document.getElementById('navbarCollapse');
      if (navMain) {
        navMain.onclick = function onClick() {
          if (navMain) {
            navMain.classList.remove("show");
          }
        }
      }
    }
  }

}
