import {zoomPlusIcon} from "../../traits/icons-tools";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  JsonPipe,
  NgFor,
  NgIf
} from '@angular/common';

declare const Zoomist;

@Component({
  selector: 'app-zoom-image',
  templateUrl: './zoom-image.component.html',
  styleUrls: ['./zoom-image.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
  ]


})


export class ZoomImageComponent implements AfterViewInit {
  @ViewChild('sliderElement') sliderElement: ElementRef;
  @ViewChild('imgElement') imgElement: ElementRef;
  @Input() srcImage: any;
  @Input() maxScale: number = 4;
  @Input() bounds: boolean = true;
  @Input() slider: boolean = true;
  @Input() zoomer: boolean = true;
  protected readonly zoomPlusIcon = zoomPlusIcon;

  ngAfterViewInit(): void {
    this.initZoomist();
    // this.initMediumZoom();
  }

  initZoomist() {

    if (this.sliderElement.nativeElement) {
      new Zoomist(this.sliderElement.nativeElement, {
        maxScale: this.maxScale,
        bounds: this.bounds,
        slider: this.slider,
        zoomer: this.zoomer
      });

    }
  }

  initMediumZoom() {
    /*   const zoomToTrigger = mediumZoom('#zoom-trigger')
       const button = document.querySelector('#button-trigger')
       button.addEventListener('click', () => zoomToTrigger.open())*/

    // mediumZoom('[data-zoomable]', {
    //   margin: 24,
    //   background: '#BADA55',
    //   scrollOffset: 0,
    //   container: '#zoom-container',
    //   template: '#zoom-template',
    // })
  }
}
