import {Injectable} from '@angular/core';
import {LocalStorage} from "./local-storage";
import {
  jsonDecrypt,
  jsonEncrypt
} from "../../traits/crypt-tools";

@Injectable({
  providedIn: 'root',
})
export class BasketServices {
  public data: any
  private STORAGE_KEY: string = 'basket';

  constructor() {
    this.init();
    this.load();
  }

  public setStorageData(clientData: any = {}) {
    return LocalStorage.setItem(this.STORAGE_KEY, jsonEncrypt(clientData));// LocalStorage.setStorageData(this.STORAGE_KEY, );
  }

  public save() {
    this.setStorageData(this.data)
    console.log(this.data, 'saveBasket')
  }

  public init() {
    this.load();
  }

  public load() {
       this.data = this.getStorageData();
    console.log(  this.data,'basketLOad')
  }

  public getStorageData() {

    let exist: any = LocalStorage.getItem(this.STORAGE_KEY)
    return exist ? jsonDecrypt(exist) : null;
    // return exist?jsonDecrypt(exist)
  }

}


