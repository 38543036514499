import {Injectable} from '@angular/core';
import {icon, iconSrc} from '../../interfaces/theme';

@Injectable({
  providedIn: 'root',
})

export class ThemeServices {
  public iconSrc :iconSrc = {
    avatar:'/assets/icons/10852416.png',
    save:'/assets/icons/save-icons/945102.png',
    zoomPlus:'/assets/icons/10023736.png',
    continue:'/assets/icons/colors-tools/2779847.png',
    list:'/assets/icons/8428362.png',
    brushesList:'/assets/icons/179600.png',
    card:'/assets/icons/867454.png',
    party:'/assets/icons/3146600.png',
    filter:'/assets/icons/7216457.png',
    brushHard:'/assets/icons/hard-brush.png',
    brush:'/assets/icons/hard-brush-sketch.png',
    brushMiddle:'/assets/icons/middle-brush-sketch.png',
    opacity100:'/assets/icons/round-brush.png',
    clear:'/assets/icons/clear.png',
    opacity50:'/assets/icons/opacity-brush.png',
    brushBig:'/assets/icons/fill-brush.png',
    eraseSoft:'assets/icons/erase-soft-brush.png',
    eraseHard:'assets/icons/erase-brush.png',
    photos:'/assets/icons/7763873.png',
    brushes:'/assets/icons/4472135.png',
    palletes:'/assets/icons/6919240.png',
    colorRound:'/assets/icons/10573532.png',
    download:'/assets/icons/1040236.png',
    ready:'/assets/icons/6276686.png',
    bookmark:'/assets/icons/7222088.png',
    clock:'/assets/icons/timer.png',
    rating:'/assets/icons/2681252.png',
    news:'/assets/icons/331953.png',
    free:'/assets/icons/6021560.png',
    premium:'/assets/icons/premium.png',
    noSrcPreview:'/assets/icons/11808042.png',
    noSrcIcon:'/assets/icons/3875172.png',
    loading:'/assets/icons/10509663.png',
    noSrcGif:'/assets/icons/gifs/empty.gif',
    references:'/assets/icons/7102051.png',
    videos:'/assets/icons/5053028.png',
    video:'/assets/icons/5930256.png',
    levelUp:'/assets/icons/6650064.png',
    human:'/assets/icons/6650064.png',
    animal:'/assets/icons/6650064.png',
    vegetable:'/assets/icons/6650064.png',
    fish:'/assets/icons/6650064.png',
  }
  public icon: icon = {
    patreon: 'fab fa-patreon',
    photos: 'fa-regular fa-images',
    uploades: 'fa-solid fa-camera-rotate',
    camera: 'fa-solid fa-camera-retro',
    random: 'fa-solid fa-shuffle',
    tag: 'fa-solid fa-tag',
    folder: 'fa-regular fa-folder-open',
    level: 'fa-solid fa-flask-vial',
    list: 'fa-solid fa-list',
    pencil: 'fa-solid fa-pencil',
    brush: 'fa-solid fa-paintbrush',
    clock: 'fa-regular fa-clock',
    category: 'fa-solid fa-certificate',
    telegram: 'fab fa-telegram-plane fa-lg icon-lg',
    viber: 'fab fa-viber fa-lg icon-lg',
    skype: 'fab fa-skype fa-lg  icon-lg',
    whatsapp: 'fab fa-whatsapp fa-lg icon-lg',
    close: 'far fa-times-circle fx-10',
    closeSquare: 'fa-regular fa-rectangle-xmark',
    save: 'far fa-save',
    archive: 'fa-solid fa-box-archive',
    colorHistory: 'fa-solid fa-droplet',
    colorPallete: 'fa-solid fa-palette',
    colorLib: 'fa-solid fa-swatchbook',
    delete: 'far fa-trash-alt',
    grid: 'fa-solid fa-border-all',
    imagePanorama: 'fa-solid fa-panorama',
    sort: 'fa-solid fa-shuffle',

    search: 'fas fa-search fx-10',
    favorite: 'far fa-heart',
    images: 'fa-solid fa-images',
    crop: 'fa-solid fa-crop',
    pin: 'fa-solid fa-thumbtack',
    favoriteOpen: 'far fa-heart',
    favoriteClose: 'fa-solid fa-heart',
    download: 'fas fa-download',
    tools: 'fa-solid fa-screwdriver-wrench',

    pallete: 'fa-solid fa-palette',
    palleteLib: 'fa-solid fa-swatchbook',
    dotesHorizontal: 'fa-solid fa-ellipsis',
    dotesVertical: 'fa-solid fa-ellipsis-vertical',
    plus: 'fa-regular fa-square-plus',
    minus: 'fa-regular fa-square-minus',
    zoomMinus: 'fa-solid fa-magnifying-glass-minus',
    zoomPlus: 'fa-solid fa-magnifying-glass-plus',
    zoom: 'fa-solid fa-magnifying-glass-plus',
    expand: 'fas fa-expand-arrows-alt',
    video: 'fas fa-video',

    libs: 'fa-solid fa-book',
    instagram: 'fab fa-instagram fa-lg icon-lg',
    facebook: 'fab fa-facebook fa-lg icon-lg',
    upArrow: 'fas fa-arrow-up',
    gifts: 'fas fa-gifts fx-10',
    cardBasket: 'fas fa-shopping-cart',
    copy: 'far fa-copy',
    card: 'fa-solid fa-chess-board',

    enter: 'fas fa-sign-in-alt',
    filter: 'fas fa-sliders-h',
    flip: 'fas fa-arrows-alt-h',
    drag: 'fa-solid fa-grip-lines',
    share: 'fas fa-share-alt-square',
    edit: 'fas fa-edit',
    add: 'far fa-plus-square',
    cube: 'fa-solid fa-cube',
    move: 'fas fa-arrows-alt',
    arrowForward: 'fa-solid fa-forward',
    arrowBackward: 'fa-solid fa-backward',
    arrowLeft: 'fa-solid fa-angle-left',
    arrowLeft2: 'fa-solid fa-arrow-left',
    arrowRight2: 'fa-solid fa-arrow-right',
    arrowRight: 'fa-solid fa-angle-right',
    arrowOneUp2: 'fa-solid fa-arrow-up',
    arrowOneUp: 'fa-solid fa-angle-up',
    arrowOneDown: 'fa-solid fa-angle-down',
    arrowOneDown2: 'fa-solid fa-arrow-down',
    eyeOpen: 'fa-regular fa-eye',
    eyeDropper: 'fa-solid fa-eye-dropper',
    siteMap: 'fa-solid fa-sitemap',
    eyeClose: 'fa-regular fa-eye-slash',
    lockOpen: 'fa-solid fa-lock-open',
    lockClose: 'fa-solid fa-lock',
    handOpen: 'fa-solid fa-hand',
    handClose: 'fa-solid fa-hand-point-up',
    gift: 'fa-solid fa-gift',
    premium: 'fa-solid fa-crown',
    clientBadge: 'fa-solid fa-id-badge',
  };

}
