<p-overlayPanel #opOrderByImage>
  <app-order-by-image-form *ngIf="showOrderByImageForm "
                           [selectModel]="selectCardImage"
                           (onClose)="showOrderByImageForm = false"
  ></app-order-by-image-form>
</p-overlayPanel>

<div class="ml-[60px] mt-2">
  <div
    [class]="'bgColor'"
    [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
    class=" bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">
    <div class="m-3 p-3">
      <div class="w-full  p-3 rounded-lg shadow-lg">
          <div class="flex justify-center">
            <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1" >
              <ng-container *ngFor="let  itemCard  of cards; let first = first">
                <div class=" rounded-lg shadow-lg  border border-zinc-700 text-center aspect-square relative  inline-block transition-transform transform hover:scale-105">
                  <img *ngIf="!itemCard.showZoom"
                       [src]="itemCard.srcPreview" alt="upload_image"
                       class=" "
                  >
                  <app-zoom-image *ngIf="itemCard.showZoom"
                    [srcImage]="itemCard.srcPreview">
                  </app-zoom-image>
                   <button *ngIf="!itemCard.showZoom"
                     (click)="itemCard.showZoom = !itemCard.showZoom; selectCardImage = itemCard;"
                            class=" z-20 text-sm absolute w-[50px] h-[50px] top-0 right-2 bg-zinc-900 text-white p-1 rounded-full transition-transform transform hover:scale-125"
                   >
                     <i   [class]="zoomPlusIcon"></i>
                   </button>
                  <button  *ngIf="itemCard.showZoom"
                    (click)="itemCard.showZoom = !itemCard.showZoom; selectCardImage = itemCard;"
                            class=" z-20 text-sm absolute w-[50px] h-[50px] top-0 right-2 bg-zinc-900 text-white p-1 rounded-full transition-transform transform hover:scale-125"
                   >
                     <i   [class]="zoomMinusIcon"></i>
                   </button>


                   <div class="flex flex-row gap-1 w-[150px] ">

                     <button #targetOrderByImage
                         class=" absolute bottom-3 right-0 w-full bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-1 px-1 rounded-md shadow-lg transition duration-300 ease-in-out transform hover:scale-105  fade-out"
                        (click)="selectCardImage = itemCard; showOrderByImageForm =true; opOrderByImage.toggle($event,targetOrderByImage)"
                     >
                    Хочу подібний!
                  <i class="fa-solid fa-circle-chevron-right"></i>
                     </button>

                   </div>
                </div>
              </ng-container>
            </div>
          </div>
      </div>
    </div>
    <div class="mt-1 mb-1">
    </div>
  </div>
</div>

