import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {PreloadService} from '../../../services/common/preload.service';
import {SidebarComponent} from '../../common/sidebar/sidebar.component';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-preload',
  templateUrl: './preload.component.html',
  standalone:true,
  imports: [
    NgIf,
    SidebarComponent
  ]


})
export class PreloadComponent implements OnInit,OnDestroy {
  @Output() onInit:EventEmitter<any> = new EventEmitter();
  @Output() onDestroy:EventEmitter<any> = new EventEmitter();
  @Output() onStartPreload:EventEmitter<any> = new EventEmitter();
  @Output() onEndPreload:EventEmitter<any> = new EventEmitter();

  constructor(public  preloadService: PreloadService) {
  }

  ngOnInit() {
    this.preloadService.hidePreload();
    this.onInit.emit('onInit')

  }
  ngOnDestroy(){
    this.onDestroy.emit('onDestroy')
  }

}
