import {Injectable} from '@angular/core';
import {timeOut} from '../../traits/datetime-tools';

@Injectable({
  providedIn: 'root'
})
export class PreloadService {

  public textLoading: string = 'Loading';
  public showModal: boolean = false;

  constructor() {}

  public hidePreload(callback: any = null):void {
    this.showModal = true;
    timeOut(() => {
      this.showModal = false;
      callback && callback(true);
    }, 1500);
  }


  public showPreload(time:any = 1500,callback: any = null):void {
    this.showModal = true;
    timeOut(() => {
      this.showModal = false;
      callback && callback(true);
    }, time);
  }
  public isShow():boolean {
    return this.showModal;
  }


}
