import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThemeServices} from '../../../services/common/theme.services';
import {NgIf} from '@angular/common';
import {GifServices} from '../../../services/common/gif.services';

@Component({
  selector: 'app-gif-generator',
  templateUrl: './gif-generator.component.html',
  standalone: true,
  imports: [NgIf]
})
export class GifGeneratorComponent implements OnInit {
  @Input() photos: any[];
  @Input() srcGif: string;
  @Input() zoomClick: boolean = false;
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  constructor(
    public theme: ThemeServices,
    public gifServices: GifServices
  ) {
  }

  ngOnInit() {
    this.gifServices.createGif(
      this.photos,
      (base64Gif: any) => {
        this.srcGif = base64Gif;
      });
  }

}
