
export class LocalStorage {

  // @ts-ignore
  private static error;

  static getItem(key: string): string {
    let result = null;
    try {
      result = localStorage.getItem(key);
    } catch (e) {
      this.error = e;
    }
    // @ts-ignore
    return result;
  }

  static setItem(key: string, value: string): void {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      this.error = e;
    }
  }

  static removeItem(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      this.error = e;
    }
  }

  static getError(): any {
    return this.error;
  }


  static setStorageData(name: any = 'login', dataObject: any = {}) {
    let stringifyData = JSON.stringify(dataObject);
    return LocalStorage.setItem(name, stringifyData);
  }

  static getStorageData(findKey: any = 'login', callback: any = null) {
    if (localStorage.getItem(findKey)) {
      let storageData = JSON.parse(LocalStorage.getItem(findKey));
      callback && callback(storageData);
      return storageData;
    }
  }
}
