import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {ScrollPanelModule} from "primeng/scrollpanel";
import {
  NgForOf,
  NgIf
} from "@angular/common";

import {OrderFormComponent} from "../order-form/order-form.component";
import {OrderByImageFormComponent} from "../order-by-image-form/order-by-image-form.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import { windowHeight } from 'src/app/traits/window-tools';
import {zoomMinusIcon, zoomPlusIcon } from 'src/app/traits/icons-tools';
import {ZoomImageComponent} from "../../../../components/zoom-image/zoom-image.component";
@Component({
  selector: 'app-view-grid-artas',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    ScrollPanelModule,
    ZoomImageComponent,
    OrderFormComponent,
    OrderByImageFormComponent,
    OverlayPanelModule,
  ],
  templateUrl: './view-grid-artas.component.html',
})
export class ViewGridArtasComponent {
  @Input() showSelect: boolean = false;
  @Input() public selectCard:any;
  @Input() public selectCardImage:any;
  @Input() public cards:any[];
  @Output() onSelect = new EventEmitter();
  public showZoom: boolean =false;
  protected readonly windowHeight = windowHeight;
  protected readonly zoomPlusIcon = zoomPlusIcon;
  public showOrderByImageForm: any =false;
  protected readonly zoomMinusIcon = zoomMinusIcon;
  ngOnInit() {
    this. selectCard = this.cards[0];
  }

  public setSelect(itemCard:any){
    this.selectCard = itemCard;
    this.onSelect.emit(itemCard);
  }

}
