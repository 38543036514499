import {Injectable} from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

const headers:HttpHeaders = new HttpHeaders();
headers.append('Access-Control-Allow-Headers', 'Content-Type');
headers.append('Access-Control-Allow-Methods', 'POST');
headers.append('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root',
})
export class BaseApi {
    constructor(public http: HttpClient) {}
    public get(url: string = ''): Observable<any> {
        return this.http.get(url);
    }
    public post(url: string = '', body:any): Observable<any> {
        return this.http.post(url, body,{headers: headers});
    }

}
