import {Component} from '@angular/core';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import { HeaderComponent } from 'src/app/components/pages/header/header.component';
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {PageArtasServices} from "../../../services/pages/page-artas.services";
import {
  ActivatedRoute,
  Router
} from "@angular/router";
import {ContactsLeftArtasComponent} from "./contacts-left-artas/contacts-left-artas.component";
import {CommonArtasComponent} from "./common-artas/common-artas.component";
import {NavbarTopArtasComponent} from "./navbar-top-artas/navbar-top-artas.component";
@Component({
  selector: 'app-page-artas',
  templateUrl: './page-artas.component.html',
  standalone:true,
  imports: [
    HeaderComponent,
    NgForOf,
    NgIf,
    ContactsLeftArtasComponent,
    CommonArtasComponent,
    NavbarTopArtasComponent,
  ],
})
export class PageArtasComponent {

  constructor(
    public dataPage: PageArtasServices,
              private router: Router,
              private route: ActivatedRoute,
    ) {}

  ngOnInit(){
    this.route.data.subscribe((dataRoute:any)=>{
      // this.dataPage = dataRoute;
      console.log(dataRoute,'dataRoute')
    })
  }
  setSelect(card: any) {
    // 'zakazat-sharj-po-foto'
    this.router.navigate(['/',card.id]);

  }
}
