import {Injectable} from '@angular/core';

import {ApiServices} from '../api/api.services';
import {timeOut} from "../../traits/datetime-tools";
import {BasketServices} from "../common/basket.services";

@Injectable({
  providedIn: 'root',
})


export class ServerOrderServices {
  constructor(
    public api: ApiServices,

              ) {

  }

  public initSite(form,selectModel,callback) {
    this.api.request(`telegram/form`,
      {...form, ...{model:selectModel}})
      .subscribe((responseSite: any): void => {
        callback && callback(responseSite);
      });
  }



}
