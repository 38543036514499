  <p-scrollPanel [style]="{height:600}">
    <div class="mt-[75px] container mx-auto">
    <div class="grid grid-cols-3 gap-4">
        @for(  photoItem  of  photos; track $index){
          <div class="relative"
          >
          <!-- <app-image-viewer-tools
              [selectImage]="photoItem"
              (onClickShowCollection)="showClientCollection = true"
              (onClickShowEduter)="showEtuder= true; showSketcher= false;showClientCollection=false"
              (onClickShowSketcher)="showSketcher= true;showEtuder= false;showClientCollection=false"
              (onClickShowRotator) = "showRotatorBtn(selectImage)"
            ></app-image-viewer-tools>-->
              <img (click)="click(photoItem)"
                class="h-auto max-w-full rounded-lg"
                   [src]="photoItem.srcPreview"
                   alt="name">
          </div>
        }
    </div>
    </div>
  </p-scrollPanel>
