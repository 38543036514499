import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Photo} from '../../../interfaces/Photo';
import {ScrollPanelModule} from 'primeng/scrollpanel';

@Component({
  selector: 'app-grid-photos', templateUrl: './grid-photos.component.html', standalone: true, imports: [
    ScrollPanelModule
  ]

})
export class GridPhotosComponent implements AfterViewInit, OnDestroy {
  @Input() photos: Array<Photo>;
  @Input() photoCurrent: Photo;
  @Input() indexCurrent: number = 0;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  public ngAfterViewInit(): void {
  }
  public ngOnDestroy(): void {
    this.destroySwiper();
  }


 click(photoItem:Photo):void{
    this.onClick.emit(photoItem)
 }
  private destroySwiper(): void {
    this.photos = null;
    this.photoCurrent = null
  }

}
