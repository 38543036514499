<p-sidebar [(visible)]="show"
           [position]="position"
           [showCloseIcon]="false"
           [fullScreen]="false"
           [baseZIndex]="zIndex"
           [modal]="modal"
           [style]="styles()"
           (onHide)="close()"
>

  <div *ngIf="showClose"
       class="absolute top-25 right-5 z-20 ">
    <button (click)="close()"
            class=" bg-color-custom text-zinc-200 ml-2 p-2">
      <fa-icon [icon]="faRectangleXmark"></fa-icon>
    </button>
  </div>
  <ng-template pTemplate="content">
    <ng-container *ngIf="show" >
      <div [class]="'bg-color-custom'"
           [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
           class=" bg-[length:50px_50px] mt-4 mb-2 bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">
<!--      <div [style.opacity]="opacity">-->
      <ng-content></ng-content>
      </div>
<!--      </div>-->
    </ng-container>
  </ng-template>

</p-sidebar>
