import {Injectable} from '@angular/core';
import  gifshot from '../../../../src/assets/params/js/gifshot.js'
// declare const gifshot: any;

@Injectable({
  providedIn: 'root',
})

export class GifServices {
  private interval: number = 1.5;

  public createGif(videosSrc: any = [], callback: any = null) {
    if (videosSrc) {
      gifshot.createGIF({
        video: videosSrc,
        images: videosSrc,
        interval: this.interval
      }, (obj: any) => {
        callback && callback(obj.image);
      });
    }
  }

}
