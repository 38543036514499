import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {ScrollPanelModule} from "primeng/scrollpanel";
import {
  NgClass,
  NgForOf,
  NgIf
} from "@angular/common";
import {RouterLink} from "@angular/router";
import {MyDataServices} from "../../../../services/my-data.services";
import { windowHeight } from 'src/app/traits/window-tools';
import {CostComponent} from "../cost/cost.component";
import {CdServices} from "../../../../services/common/cd.services";
@Component({
  selector: 'app-product-list-right-artas',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NgClass,
    ScrollPanelModule,
    RouterLink,
    CostComponent,
  ],
  templateUrl: './product-list-right-artas.component.html',
})
export class ProductListRightArtasComponent {
  @Input() public  cards:any;
  @Input() public  selectCard:any;
  @Output() onSelectCard = new EventEmitter();

  constructor(public cd:CdServices,) {
  }
  ngOnInit() {
  }
  ngAfterViewInit(){
  }


  protected readonly windowHeight = windowHeight;
}
