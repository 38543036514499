import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  JsonPipe,
  NgForOf,
  NgIf
} from "@angular/common";
import { randomId } from 'src/app/traits/string-tools';
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ServerOrderServices} from "../../../../services/servers/server-order.services";
import {BasketServices} from "../../../../services/common/basket.services";
import {SidebarComponent} from "../../../../components/common/sidebar/sidebar.component";
import {CostComponent} from "../cost/cost.component";
import {CdServices} from "../../../../services/common/cd.services";

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  standalone:true,
  imports: [
    NgForOf,
    NgIf,
    FormsModule,
    SidebarComponent,
    InputTextModule,
    InputTextareaModule,
    CostComponent,
  ],
})
export class OrderFormComponent implements OnInit, AfterViewInit {
  @Output() onSubmit = new EventEmitter();
  @Input() nameOrder: any;
  @Input() formStorageName: any = 'form';
  @Input() srcPreview: any;
  @Input() showOrderCard: any =true;
  public form: any = {
    nameClient: '',
    phone: '',
    comment: '',
    validation: true,
    checked: [],
    stringChecked: '',
    // setName: this.cd.set.name,
    // setDesc: this.cd.set.desc,
    // srcPreview: this.cd.set.srcPreview,
  };
  public showCardOrder: boolean = true;
  @Input() selectModel: any;
  @Output() onClose = new EventEmitter();

  constructor(public cd:CdServices,public serverOrder:ServerOrderServices,
              public basketServices: BasketServices,
  ) {
  }
  getDiscount (cost,discount:any = 20) {
    cost = cost.replace(/\D/g,'')
    return   cost - (cost * discount / 100);
  }

  ngOnInit() {
    this.initFormStorage();

  }

  checkedAdds(checkedList: any) {
    let stringChecked = '|';
    checkedList.map((check: any) => {
      return stringChecked += check.name + '|';
    });
    // let sringChecked = checkedList.join('|');
    console.log(stringChecked, 'this.checked');
    this.form.stringChecked = stringChecked;
  }

  submit(selectModel: any) {
    this.showCardOrder = false;
this.serverOrder.initSite(
  this.form,
  this.selectModel,
  (responseTelegramBot:any)=>{
    console.log(responseTelegramBot)
    // this.cd.alert.callbackMe();
    this.basketServices.data = {
      ...this.form,
      ...this.selectModel
    }
    this.basketServices.save()
    this.saveFormToStorage();

    this.hide();
  }
  )

  /*  this.cd.api.request(`telegram/form`,
      {...this.form, ...
        {nameOrder: selectModel.name
        }})
      .subscribe((collectionFlag: any) => {
        this.cd.alert.callbackMe();
        this.saveFormToStorage();
        this.clear();
      });*/
  }

  ngAfterViewInit(): void {
  }

private  clear() {
    this.form.nameClient = '';
    this.form.phone = '';
    this.form.comment = '';
    this.form.checked = [];
    this.form.stringChecked = '';
  }
 public hide() {
    this.showCardOrder =false;
    this.onClose.emit(false);

    this.clear();
  }


 public  timeConverter(UNIX_timestamp:any){
    let a = new Date(UNIX_timestamp * 1000);
    let months = ['Січень','Лютий','Березень','Квітень','Травень','Червень','Липень','Серпень','Серпень','Жовтень','Листопад','Грудень'];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    let hour = a.getHours();
    let min = a.getMinutes();
    let sec = a.getSeconds();
    let time = date + ' ' + month + ' ' + hour + ':' + min + ':' + sec ;
    return time;
  }
  public saveFormToStorage() {
    this.form.model =
      {
        id: randomId(),
        createAt: Date.now(),
        name: this.selectModel.name,
        cost: this.selectModel.cost,
        desc: this.selectModel.desc,
        costDiscount: this.selectModel.costDiscount,
        srcPreview: this.selectModel.srcPreview,
      };
    // this.cd.setStorageData('form', this.form);

  }

  public initFormStorage() {
/*
    let formStorage = this.cd.getStorageData(this.formStorageName);
    if (formStorage) {
      this.form = formStorage;
      this.cd.form = formStorage
    }*/
  }

}
