import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgFor, NgIf} from '@angular/common';
import {CardArtasComponent} from "../card-artas/card-artas.component";

@Component({
  selector: 'app-list-artas',
  templateUrl: './list-artas.component.html',
standalone:true,
  imports: [
    NgFor,
    NgIf,
    CardArtasComponent,
  ]

})
export class ListArtasComponent {

  @Input() cards:any[];
  @Input() showFilter: boolean = false;
  public selectItem: object;
  public indexCard: number = 0;
  public maxView: number = 100;
  public selectModelCurrent: any;
  public bgColor: string ='bg-color-custom';
  public bgImage: string ='assets/background_logo_small.png';
  @Output() onSelect:EventEmitter<any> = new EventEmitter();
  @Output() onSelectCourse:EventEmitter<any> = new EventEmitter();
  @Output() onShowPhotos:EventEmitter<any> = new EventEmitter();
  @Output() onFrameShow:EventEmitter<any> = new EventEmitter();
  @Output() onClickRoute:EventEmitter<any> = new EventEmitter();

}
