import {Component, Input} from '@angular/core';
import {NgStyle} from "@angular/common";
@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  standalone:true,
  imports:[NgStyle]


})
export class SvgIconComponent  {
  @Input() pathD1: string = 'M2 1842 c5 -37 229 -459 248 -469 14 -8 1352 -12 1445 -4 l45 3 47 87 c26 47 56 100 68 116 12 17 38 69 59 118 21 48 42 87 47 87 8 0 42 70 37 76 -5 4 -71 7 -131 5 -45 -1 -59 -5 -65 -19 -4 -10 -43 -84 -87 -165 l-80 -147 -610 1 c-335 0 -627 3 -648 7 -38 7 -41 11 -111 137 -123 219 -99 195 -190 195 l-78 0 4 -28z';
  @Input() pathD2: string = 'M1000 1262 c0 -9 -81 -12 -335 -12 -302 0 -335 -2 -335 -16 0 -9 94 -192 208 -408 115 -215 260 -489 323 -608 108 -207 115 -217 143 -218 16 0 26 3 23 7 -4 3 2 20 12 37 20 34 83 154 211 401 46 88 156 298 246 466 90 169 164 314 164 323 0 14 -33 16 -318 16 -234 0 -321 3 -330 12 -9 9 -12 9 -12 0z m392 -204 c-47 -79 -248 -452 -328 -608 -21 -41 -45 -88 -55 -103 l-16 -28 -43 83 c-24 46 -72 135 -108 198 -36 63 -75 137 -87 165 -13 27 -37 74 -54 103 -41 70 -111 200 -111 206 0 3 168 7 373 9 204 1 389 3 410 5 l39 2 -20 -32z';
  @Input() color: string = '#d97706';
  @Input() width: number = 16.3;
  @Input() height: number = 26.9;
  @Input() stroke: string = 'none';
}
