
<div #sliderElement
     id="zoomistId" class="zoomist-container">
  <div class="zoomist-wrapper">
    <div  class="zoomist-image">
      <img  #imgElement class=" min-w-3 min-h-2 w-auto h-auto"
           [src]="srcImage"
           alt="ref image"
      >
    </div>
  </div>
</div>
