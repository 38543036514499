export function randomId() {
  return Math.random().toString(36).substr(2, 9);
};
export function getDiscount (cost:any,discount:any = 30) {
  cost = cost.replace(/\D/g,'')
  return   cost - (cost * discount / 100);
}
export function clearCurrency(cost:any) {
 return  cost.replace('грн','');
}
export function capitalizeFirstLetter(string: any) {
  return string[0].toUpperCase() + string.slice(1);
}

export function replaceString(string: any, newString: any = '', replace: any = 'art-club.artas.org.ua/') {
  return string.replace(replace, newString);
}
/* public checkEmail(email: any, emailError: any) {
    this.showPatreonMemberSpinner || !email || email === emailError || !this.validateEmail(email);
  }*/
export function boxFileName(model: any) {
  let boxId = model.id + '_' + model.createAt;
  return boxId.replace(/\s/g, "");
}
export function clearTextRow(model:any,nameRow:string):string {
  return model[nameRow] = '';
}
